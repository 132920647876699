import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ButtonProps } from '@mui/material/Button';
import { PageTitle, TextField, Dialog, StyledButton, StyledCheckbox, RadioGroup, StyledRadioButton } from 'components';
import { Props as DialogProps } from 'components/Dialog/Dialog.types';
import { PATH } from 'router/routes';
import { useFetch, useSendRequest, useToggle } from 'lib/hooks';
import { FIELDS, Props as ComponentProps, FormikSubmitValues } from '../types';
import { createTheme, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { useAuth } from 'oidc-react';
import { getDictionariesUsers, getRiskGroups } from 'api/services';
import {
  DebtDefinitionState,
  DebtRangeDto,
  DebtReportDefinitionDto,
  DetailedCounterpartyList,
  ReportRiskGroupItemDto,
  ReportSharingMethod,
  ReportUsersDto,
  RiskGroupDefinitionState,
  RiskGroupDto,
  RiskGroupItemDto,
  RiskGroupReportDefiniitionDto,
  TradeCreditLimitDefinitionState,
  TradeCreditLimitFilterDto,
  TradeCreditLimitRangeDto
} from 'api/models';
import { reportSchema } from '../schema';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage } from 'store/success';
import { setErrors, resetErrors, selectErrors } from 'store/errors';
import { ERRORS } from 'types/errors';
import { ROLE } from 'config/oidc';
import RecipientsList from './RecipientsList';
import styles from './ReportForm.module.scss';
import ComboBox from 'components/ComboCox';
import { SelectValue } from 'components/ComboCox/ComboBox.types';
import { numberWithSpace } from 'lib/utils';

export const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
});

const allChangesOptionValue = 'all';
const noDebtOptionValue = 'noDebt';

const riskGroupDeclineTresholdDefaultOptionValue = 'E';
const tradeCreditLimitDeclineThresholdsDefaultOptionValue = '50000';
const debtImprovementTresholdDefaultOptionValue = '50000';

const tradeCreditLimitThresholds = [5_000, 10_000, 25_000, 50_000, 100_000, 500_000, 1_000_000];
const debtThresholds = [2_000, 5_000, 10_000, 50_000, 100_000, 500_000, 1_000_000];

const tradeCreditLimitRanges = [
  {
    lowerBound: 0,
    upperBound: tradeCreditLimitThresholds[0],
    isDefined: true
  },
  ...tradeCreditLimitThresholds.map<TradeCreditLimitRangeDto>((value, index) => {
    return {
      lowerBound: value + 1,
      upperBound: tradeCreditLimitThresholds[index + 1] ?? null,
      isDefined: true
    };
  }),
  {
    lowerBound: null,
    upperBound: null,
    isDefined: false
  }
];

const debtRanges = [
  {
    lowerBound: 0.01,
    upperBound: debtThresholds[0],
    isDefined: true
  },
  ...debtThresholds.map<DebtRangeDto>((value, index) => {
    return {
      lowerBound: value + 0.01,
      upperBound: debtThresholds[index + 1] ?? null,
      isDefined: true
    };
  }),
  {
    lowerBound: null,
    upperBound: null,
    isDefined: false
  }
];

const ReportForm: FC<ComponentProps> = ({ inEditMode, initialValues, submitHandler }) => {
  const [isAcceptModalOpen, setAcceptModalOpen] = useState(false);
  const [isCancelModalOpen, toggleCancelModal] = useToggle();
  const [isCleanModalOpen, toggleCleanModal] = useToggle();
  const [currentCompany, setCurrentCompany] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');
  const [showReportContentSectionError, setShowReportContentSectionError] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { Errors: errors } = useSelector(selectErrors);

  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;

  useEffect(() => {
    if (companyId) {
      setCurrentCompany(companyId);
    }
  }, [companyId]);

  const { result: riskGroupsDict } = useFetch<RiskGroupDto>(
    useCallback(() => getRiskGroups(true), [currentCompany]),
    [currentCompany],
    !currentCompany
  );

  const { result: users } = useFetch<ReportUsersDto>(
    useCallback(() => getDictionariesUsers(currentCompany), [currentCompany]),
    [currentCompany],
    !currentCompany
  );

  const {
    result: postResult,
    isLoading: isDataSending,
    validationErrors,
    sendData,
    setValidationErrors
  } = useSendRequest();

  const submitCallback = useCallback(() => {
    setAcceptModalOpen(false);
    dispatch(setSuccessMessage('Dane zostały zapisane'));
  }, [id, inEditMode, navigate, dispatch]);

  const errorCallback = useCallback(() => {
    if (inEditMode) setAcceptModalOpen(false);

    dispatch(resetErrors());

    dispatch(
      setErrors({
        Errors: [{ ErrorCode: ERRORS.ERROR_FORMIK_SUBMIT_FAILED, Message: 'Nie udało się zapisać zmian.' }],
        ErrorId: ERRORS.ERROR_FORMIK_SUBMIT_FAILED
      })
    );

    setValidationErrors([]);
  }, [inEditMode, validationErrors, dispatch]);

  let riskGroupDeclineTresholdIdDefaultOptionValue = useMemo(() => {
    const riskGroupDeclineTresholdDefault: string =
      riskGroupsDict?.riskGroups
        .filter((v) => v.code === riskGroupDeclineTresholdDefaultOptionValue)
        .map((v) => v.id)
        .toString() ?? '';

    if (!inEditMode) {
      initialValues[FIELDS.RISK_GROUP_DECLINE_THRESHOLD] = riskGroupDeclineTresholdDefault;
    }
    return riskGroupDeclineTresholdDefault;
  }, [riskGroupsDict]);

  const formik = useFormik({
    initialValues,
    validationSchema: reportSchema,
    onSubmit: (values, helpers) => {
      const recipients =
        users?.results
          .filter((item) => formik.values[FIELDS.RECIPIENTS].includes(item.id))
          .map((item) => ({ ...item, externalId: item.id })) ?? [];
      const riskGroups =
        riskGroupsDict?.riskGroups
          .filter((item) => formik.values[FIELDS.RISK_GROUPS].includes(item.id))
          .map((item) => ({ ...item, externalId: item.id })) ?? [];
      const frequencyToNumber = Number(formik.values[FIELDS.FREQUENCY]);

      const detailedCounterpartyListToNumber = Number(formik.values[FIELDS.DETAILED_COUNTERPARTY_LIST]);

      const riskGroupDefinitionState = Number(formik.values[FIELDS.RISK_GROUP_DEFINITION_STATE]);

      let riskGroupDefinition: RiskGroupReportDefiniitionDto;

      if (riskGroupDefinitionState === RiskGroupDefinitionState.CustomDefinition) {
        const useImprovementFilter = values[FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER];
        const useDeclineFilter = values[FIELDS.RISK_GROUP_USE_DECLINE_FILTER];

        const improvementThreshold: ReportRiskGroupItemDto | null =
          values[FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD] !== allChangesOptionValue
            ? {
                externalId: Number(values[FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD])
              }
            : null;

        const declineThreshold: ReportRiskGroupItemDto | null =
          values[FIELDS.RISK_GROUP_DECLINE_THRESHOLD] !== allChangesOptionValue
            ? {
                externalId: Number(values[FIELDS.RISK_GROUP_DECLINE_THRESHOLD])
              }
            : null;

        riskGroupDefinition = {
          riskGroupDefinitionState: riskGroupDefinitionState,
          useImprovementFilter: useImprovementFilter,
          improvementThreshold: useImprovementFilter ? improvementThreshold : null,
          useDeclineFilter: useDeclineFilter,
          declineThreshold: useDeclineFilter ? declineThreshold : null,
          riskGroups: riskGroups.length > 0 ? riskGroups : null
        };
      } else {
        riskGroupDefinition = {
          riskGroupDefinitionState: riskGroupDefinitionState
        };
      }

      const tradeCreditLimitDefinitionState = Number(formik.values[FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]);

      let tradeCreditLimitFilter: TradeCreditLimitFilterDto;

      if (tradeCreditLimitDefinitionState === TradeCreditLimitDefinitionState.CustomDefinition) {
        const useImprovementFilter = values[FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD];
        const useDeclineFilter = values[FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD];

        let improvementThreshold: number | null;
        if (values[FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD] === allChangesOptionValue) {
          improvementThreshold = null;
        } else {
          improvementThreshold = Number(values[FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD]);
        }

        let declineThreshold: number | null;
        if (values[FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD] === allChangesOptionValue) {
          declineThreshold = null;
        } else {
          declineThreshold = Number(values[FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD]);
        }

        const rangeFilters = values[FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER];

        tradeCreditLimitFilter = {
          tradeCreditLimitDefinitionState: tradeCreditLimitDefinitionState,
          useImprovementThreshold: useImprovementFilter,
          improvementThreshold: useImprovementFilter ? improvementThreshold : null,
          useDeclineThreshold: useDeclineFilter,
          declineThreshold: useDeclineFilter ? declineThreshold : null,
          useRangeFilter: rangeFilters.length > 0,
          rangeFilter: rangeFilters.length > 0 ? rangeFilters : null
        };
      } else {
        tradeCreditLimitFilter = {
          tradeCreditLimitDefinitionState: tradeCreditLimitDefinitionState
        };
      }

      let debtReportDefinition: DebtReportDefinitionDto;

      debtReportDefinition = {
        debtDefinitionState: Number(formik.values[FIELDS.DEBT_DEFINITION_STATE])
      };

      if (debtReportDefinition.debtDefinitionState === DebtDefinitionState.CustomDefinition) {
        debtReportDefinition.useImprovementThreshold = values[FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD];
        debtReportDefinition.useDeclineThreshold = values[FIELDS.DEBT_USE_DECLINE_THRESHOLD];
        debtReportDefinition.addingDebtor = values[FIELDS.DEBT_ADDING_DEBTOR];
        debtReportDefinition.removingDebtor = values[FIELDS.DEBT_REMOVING_DEBTOR];

        if (
          debtReportDefinition.useImprovementThreshold === false ||
          values[FIELDS.DEBT_IMPROVEMENT_THRESHOLD] === allChangesOptionValue
        ) {
          debtReportDefinition.improvementThreshold = null;
        } else {
          debtReportDefinition.improvementThreshold = Number(values[FIELDS.DEBT_IMPROVEMENT_THRESHOLD]);
        }

        if (
          debtReportDefinition.useDeclineThreshold === false ||
          values[FIELDS.DEBT_DECLINE_THRESHOLD] === allChangesOptionValue
        ) {
          debtReportDefinition.declineThreshold = null;
        } else if (values[FIELDS.DEBT_DECLINE_THRESHOLD] === noDebtOptionValue) {
          debtReportDefinition.declineThreshold = {
            threshold: null,
            isDefined: false
          };
        } else {
          debtReportDefinition.declineThreshold = {
            threshold: Number(values[FIELDS.DEBT_DECLINE_THRESHOLD]),
            isDefined: true
          };
        }

        const rangeFilters = values[FIELDS.DEBT_RANGE_FILTER];
        debtReportDefinition.useRangeFilter = rangeFilters.length > 0;
        debtReportDefinition.rangeFilter = rangeFilters.length > 0 ? rangeFilters : null;
      }

      const newValues: FormikSubmitValues = {
        companyId: Number(companyId),
        name: values[FIELDS.NAME],
        frequency: frequencyToNumber,
        recipients: recipients,
        reportSharingMethod: formik.values[FIELDS.REPORT_SHARING_METHOD],
        detailedCounterpartyList: detailedCounterpartyListToNumber,
        riskGroupDefinition: riskGroupDefinition,
        tradeCreditLimitFilter: tradeCreditLimitFilter,
        debtReportDefinition: debtReportDefinition
      };

      sendData(() => submitHandler(newValues), submitCallback, errorCallback);
    }
  });

  const bestRiskGroup = 'A';
  const worstRiskGroup = 'H';
  const notCalculatedRiskGroup = 'X';

  const riskGroupImprovementOptions = useMemo(() => {
    const allChangesOption: SelectValue = {
      value: allChangesOptionValue,
      text: 'Wszystkie zmiany'
    };

    const riskGroupOptions =
      riskGroupsDict?.riskGroups
        ?.filter((value) => value.code !== notCalculatedRiskGroup && value.code !== worstRiskGroup)
        .map<SelectValue>((riskGroup) => {
          return {
            value: riskGroup.id.toString(),
            text: riskGroup.code === bestRiskGroup ? `Do ${riskGroup.code}` : `Do ${riskGroup.code} lub lepszej`
          };
        }) ?? [];

    return [allChangesOption, ...riskGroupOptions];
  }, [riskGroupsDict]);

  const riskGroupDeclineOptions = useMemo(() => {
    const allChangesOption: SelectValue = {
      value: allChangesOptionValue,
      text: 'Wszystkie zmiany'
    };

    const riskGroupOptions =
      riskGroupsDict?.riskGroups
        ?.filter((value) => value.code !== notCalculatedRiskGroup && value.code !== bestRiskGroup)
        .map<SelectValue>((riskGroup) => {
          return {
            value: riskGroup.id.toString(),
            text: riskGroup.code === worstRiskGroup ? `Do ${riskGroup.code}` : `Do ${riskGroup.code} lub gorszej`
          };
        }) ?? [];

    return [allChangesOption, ...riskGroupOptions];
  }, [riskGroupsDict]);

  const tradeCreditLimitImprovementOptions: SelectValue[] = [
    {
      value: allChangesOptionValue,
      text: 'Wszystkie zmiany'
    },
    ...tradeCreditLimitThresholds.map<SelectValue>((value) => {
      return {
        value: value.toString(),
        text: `Do ${numberWithSpace(value)} zł lub wyżej`
      };
    })
  ];

  const tradeCreditLimitDeclineOptions: SelectValue[] = [
    {
      value: allChangesOptionValue,
      text: 'Wszystkie zmiany'
    },
    ...tradeCreditLimitThresholds.map<SelectValue>((value) => {
      return {
        value: value.toString(),
        text: `Do ${numberWithSpace(value)} zł lub niżej`
      };
    })
  ];

  const debtImprovementOptions: SelectValue[] = [
    {
      value: allChangesOptionValue,
      text: 'Wszystkie zmiany'
    },
    ...debtThresholds.map<SelectValue>((value) => {
      return {
        value: value.toString(),
        text: `Do ${numberWithSpace(value)} zł lub wyżej`
      };
    })
  ];

  const debtDeclineOptions: SelectValue[] = [
    {
      value: allChangesOptionValue,
      text: 'Wszystkie zmiany'
    },
    ...debtThresholds.map<SelectValue>((value) => {
      return {
        value: value.toString(),
        text: `Do ${numberWithSpace(value)} zł lub niżej`
      };
    }),
    {
      value: noDebtOptionValue,
      text: 'Brak zadłużenia'
    }
  ];

  const getTradeCreditRangeLabel = (range: TradeCreditLimitRangeDto) => {
    if (range.isDefined) {
      return `${numberWithSpace(range.lowerBound ?? 0)} ${
        range.upperBound ? `- ${numberWithSpace(range.upperBound)} zł` : ` zł i wyżej`
      }`;
    } else return 'Limit niewyznaczony';
  };

  const getDebtRangeLabel = (range: DebtRangeDto) => {
    if (range.isDefined) {
      return `${numberWithSpace(range.lowerBound ?? 0)} ${
        range.upperBound ? `- ${numberWithSpace(range.upperBound)} zł` : ` zł i wyżej`
      }`;
    } else return 'Brak zadłużenia';
  };

  useEffect(() => {
    if (!isDataSending && postResult) {
      setTimeout(closeWindow, 5000);
    }
  }, [isDataSending, inEditMode, postResult, navigate]);

  const isAnyReportSectionEnabled =
    formik.values[FIELDS.RISK_GROUP_DEFINITION_STATE] !== RiskGroupDefinitionState.Disabled ||
    formik.values[FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE] !== TradeCreditLimitDefinitionState.Disabled ||
    formik.values[FIELDS.DEBT_DEFINITION_STATE] !== DebtDefinitionState.Disabled;

  const checkRequiredFields = () => {
    const requiredFields = [FIELDS.NAME];

    return requiredFields.every((field) => formik.values[field]) && isAnyReportSectionEnabled;
  };

  const shouldFormBeDisabled =
    !checkRequiredFields() && errors.length === 0
      ? true
      : false || Object.keys(formik.errors).length > 0 || validationErrors.length > 0 || !!postResult || !formik.dirty;

  const shouldCleanButtonBeDisabled = !!postResult || !formik.dirty || isDataSending;

  const closeWindow = (): void => {
    window.close();
  };

  const acceptButtonProps: ButtonProps = {
    variant: 'contained',
    disabled: shouldFormBeDisabled || isDataSending,
    type: inEditMode ? 'button' : 'submit',
    onClick: inEditMode ? () => setAcceptModalOpen(true) : undefined
  };

  const resetRiskGroupAdvancedSettings = async () => {
    await Promise.all([
      formik.setFieldValue(FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER, false),
      formik.setFieldValue(FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD, allChangesOptionValue),
      formik.setFieldValue(FIELDS.RISK_GROUP_USE_DECLINE_FILTER, true),
      formik.setFieldValue(FIELDS.RISK_GROUP_DECLINE_THRESHOLD, riskGroupDeclineTresholdIdDefaultOptionValue),
      formik.setFieldValue(FIELDS.RISK_GROUPS, [])
    ]);
    formik.validateForm();
  };

  const resetTradeCreditLimitAdvancedSettings = async () => {
    await Promise.all([
      formik.setFieldValue(FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD, false),
      formik.setFieldValue(FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD, allChangesOptionValue),
      formik.setFieldValue(FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD, true),
      formik.setFieldValue(
        FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD,
        tradeCreditLimitDeclineThresholdsDefaultOptionValue
      ),
      formik.setFieldValue(FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER, [])
    ]);
    formik.validateForm();
  };

  const resetDebtAdvancedSettings = async () => {
    await Promise.all([
      formik.setFieldValue(FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD, true),
      formik.setFieldValue(FIELDS.DEBT_IMPROVEMENT_THRESHOLD, debtImprovementTresholdDefaultOptionValue),
      formik.setFieldValue(FIELDS.DEBT_USE_DECLINE_THRESHOLD, false),
      formik.setFieldValue(FIELDS.DEBT_DECLINE_THRESHOLD, allChangesOptionValue),
      formik.setFieldValue(FIELDS.DEBT_ADDING_DEBTOR, true),
      formik.setFieldValue(FIELDS.DEBT_REMOVING_DEBTOR, true),
      formik.setFieldValue(FIELDS.DEBT_RANGE_FILTER, [])
    ]);
    formik.validateForm();
  };

  const resetRiskGroupAdvancedSettingsButtonProps: ButtonProps = {
    variant: 'contained',
    disabled: false,
    type: 'reset',
    onClick: resetRiskGroupAdvancedSettings
  };

  const resetTradeCreditLimitAdvancedSettingsButtonProps: ButtonProps = {
    variant: 'contained',
    disabled: false,
    type: 'reset',
    onClick: resetTradeCreditLimitAdvancedSettings
  };

  const resetDebtAdvancedSettingsButtonProps: ButtonProps = {
    variant: 'contained',
    disabled: false,
    type: 'reset',
    onClick: resetDebtAdvancedSettings
  };

  const cleanButtonProps: ButtonProps = {
    onMouseDown: toggleCleanModal,
    variant: 'text',
    disabled: shouldCleanButtonBeDisabled,
    type: 'button'
  };

  const cancelButtonLabel = inEditMode
    ? 'Czy chcesz anulować modyfikację ustawień raportu?’'
    : 'Czy chcesz anulować dodanie nowego raportu?';

  const cleanButtonLabel = 'Czy chcesz wyczyścić zawartość raportu?';

  const cancelDialogProps: DialogProps = {
    isOpen: isCancelModalOpen,
    closeModal: toggleCancelModal,
    accept: inEditMode ? () => navigate(`${PATH.REPORTS_LIST}`) : closeWindow,
    acceptLabel: 'Tak',
    closeLabel: 'Nie'
  };

  const cleanDialogProps: DialogProps = {
    isOpen: isCleanModalOpen,
    closeModal: toggleCleanModal,
    accept: () => {
      formik.resetForm();
      toggleCleanModal();
    },
    acceptLabel: 'Tak',
    closeLabel: 'Nie'
  };

  const radioBoxHandler = (event: any, field: FIELDS) => {
    const { value, checked } = event.target;

    if (checked) {
      formik.setFieldValue(field, Number(value));
    }
  };

  const availableInServiceAndEmailRadioBoxHandler = (event: any) => {
    const { value, checked } = event.target;

    if (checked) {
      formik.setFieldValue(FIELDS.DETAILED_COUNTERPARTY_LIST, DetailedCounterpartyList.FileAvailableInServiceAndEmail);
      formik.setFieldValue(FIELDS.REPORT_SHARING_METHOD, Number(value));
    }
  };

  const fileAvailableInServiceRadioBoxHandler = (event: any) => {
    const { value, checked } = event.target;

    if (checked) {
      formik.setFieldValue(FIELDS.DETAILED_COUNTERPARTY_LIST, DetailedCounterpartyList.FileAvailableInService);
      setSearchValue('');
      formik.setFieldValue(FIELDS.RECIPIENTS, []);
      formik.setFieldValue(FIELDS.REPORT_SHARING_METHOD, Number(value));
    }
  };

  const stateDefinitionRadioButtonHandler = (event: any, field: FIELDS) => {
    const { value, checked } = event.target;

    if (checked) {
      formik.setFieldValue(field, Number(value));
    }
    setShowReportContentSectionError(true);
  };

  const checkBoxHandler = (event: any, field: FIELDS) => {
    const { checked } = event.target;
    formik.setFieldValue(field, checked);
  };

  const comboBoxHandler = (value: string, field: FIELDS) => {
    formik.setFieldValue(field, value);
  };

  const riskGroupParentCheckBoxHandler = (event: any) => {
    const { checked } = event.target;
    if (checked) {
      formik.setFieldValue(
        FIELDS.RISK_GROUPS,
        riskGroupsDict?.riskGroups.map((riskGroup) => riskGroup.id)
      );
    } else {
      formik.setFieldValue(FIELDS.RISK_GROUPS, []);
    }
  };

  const tradeCreditLimitParentCheckBoxHandler = (event: any) => {
    const { checked } = event.target;
    if (checked) {
      formik.setFieldValue(FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER, tradeCreditLimitRanges);
    } else {
      formik.setFieldValue(FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER, []);
    }
  };

  const debtParentCheckBoxHandler = (event: any) => {
    const { checked } = event.target;
    if (checked) {
      formik.setFieldValue(FIELDS.DEBT_RANGE_FILTER, debtRanges);
    } else {
      formik.setFieldValue(FIELDS.DEBT_RANGE_FILTER, []);
    }
  };

  const multiCheckboxHandler = useCallback((event: any, field: FIELDS, currentValues: number[]) => {
    const { value, checked } = event.target;
    const valueNumber = Number(value);
    if (checked) {
      const newValues = [...currentValues, valueNumber];
      formik.setFieldValue(field, newValues);
    } else {
      const newValues = currentValues.filter((item) => item !== valueNumber);
      formik.setFieldValue(field, newValues);
    }
  }, []);

  const tradeCreditLimitMultiCheckboxHandler = useCallback(
    (event: any, field: FIELDS, currentValues: TradeCreditLimitRangeDto[]) => {
      const { value: valueIndex, checked } = event.target;
      const value = tradeCreditLimitRanges.at(Number(valueIndex));
      if (checked) {
        const newValues = [...currentValues, value];
        formik.setFieldValue(field, newValues);
      } else {
        const newValues = currentValues.filter((item) => item !== value);
        formik.setFieldValue(field, newValues);
      }
    },
    []
  );

  const debtMultiCheckboxHandler = useCallback((event: any, field: FIELDS, currentValues: DebtRangeDto[]) => {
    const { value: valueIndex, checked } = event.target;
    const value = debtRanges.at(Number(valueIndex));
    if (checked) {
      const newValues = [...currentValues, value];
      formik.setFieldValue(field, newValues);
    } else {
      const newValues = currentValues.filter((item) => item !== value);
      formik.setFieldValue(field, newValues);
    }
  }, []);

  const checkAll = useCallback((event: any, field: FIELDS, availableValues: number[]) => {
    if (event.target.checked) {
      formik.setFieldValue(field, availableValues);
    } else {
      formik.setFieldValue(field, []);
    }
  }, []);

  const pageTitle = inEditMode ? 'Modyfikacja raportu' : 'Nowy raport';

  return (
    <Box sx={{ padding: '1rem 0' }}>
      <PageTitle dataTest="new-report-page-title" sx={{ padding: '0 1.5rem' }}>
        {pageTitle}
      </PageTitle>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            background: '#e7e6e6'
          }}>
          <Box
            sx={{ padding: '0 1.5rem', fontSize: '20px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
            Ustawienia ogólne
          </Box>
        </Box>
        <Box sx={{ display: 'flex', paddingBottom: '2.25rem' }}>
          <Box sx={{ padding: '0 1.5rem', width: '50%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Box sx={{ fontSize: '20px', marginTop: '20px' }}>Nazwa raportu</Box>
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} key={1}>
                <TextField
                  key={'Nazwa raportu'}
                  field={{
                    name: FIELDS.NAME,
                    label: 'Nazwa raportu',
                    value: formik.values[FIELDS.NAME],
                    error: formik.touched[FIELDS.NAME] && !!formik.errors[FIELDS.NAME],
                    helperText: formik.touched[FIELDS.NAME] && formik.errors[FIELDS.NAME]
                  }}
                  validationErrors={validationErrors}
                  onChangeHandler={formik.handleChange}
                  onBlurHandler={formik.handleBlur}
                  errorsSetter={setValidationErrors}
                  autoFocus={true}
                  disabled={false}
                  dataTest="new-report-client-name-input"
                />
              </Box>
            </Box>
            <Box sx={{ margin: '1rem 0 0 0' }}>
              <Box sx={{ fontSize: '20px', marginTop: '20px' }}>Częstotliwość wysyłania raportu</Box>
              <RadioGroup title="" noPadding={true} isLast={true}>
                <Box sx={{ display: 'flex', padding: '0 0 0 .3rem', flexDirection: 'column' }}>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.FREQUENCY)}
                      value={1}
                      name={'frequency'}
                      key={'b1'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={Number(formik.values[FIELDS.FREQUENCY]) === 1}
                          id="frequency-1"
                        />
                      }
                      label={'co 1 dzień'}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.FREQUENCY)}
                      value={7}
                      name={'frequency'}
                      key={'b2'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={Number(formik.values[FIELDS.FREQUENCY]) === 7}
                          id="frequency-2"
                        />
                      }
                      label={'co 1 tydzień (poniedziałek)'}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.FREQUENCY)}
                      value={14}
                      name={'frequency'}
                      key={'b3'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={Number(formik.values[FIELDS.FREQUENCY]) === 14}
                          id="frequency-3"
                        />
                      }
                      label={'co 2 tygodnie (poniedziałek)'}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.FREQUENCY)}
                      value={30}
                      name={'frequency'}
                      key={'b4'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={Number(formik.values[FIELDS.FREQUENCY]) === 30}
                          id="frequency-4"
                        />
                      }
                      label={'co miesiąc (1-szego kalendarzowego dnia miesiąca)'}
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </Box>
            <Box sx={{ margin: '1rem 0 0 0' }}>
              <Box sx={{ fontSize: '20px', marginTop: '20px' }}>Sposób udostępnienia raportu</Box>
              <RadioGroup title="" noPadding={true} isLast={true}>
                <Box sx={{ display: 'flex', padding: '0 0 0 .3rem', flexDirection: 'column' }}>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => availableInServiceAndEmailRadioBoxHandler(event)}
                      value={1}
                      name={'reportSharingMethod'}
                      key={'b1'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={
                            formik.values[FIELDS.REPORT_SHARING_METHOD] ===
                            ReportSharingMethod.AvailableInServiceAndEmail
                          }
                          id="report-sharing-method-1"
                        />
                      }
                      label={'poprzez serwis oraz email'}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => fileAvailableInServiceRadioBoxHandler(event)}
                      value={2}
                      name={'reportSharingMethod'}
                      key={'b2'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={
                            formik.values[FIELDS.REPORT_SHARING_METHOD] === ReportSharingMethod.AvailableInService
                          }
                          id="report-sharing-method-2"
                        />
                      }
                      label={'tylko poprzez serwis'}
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </Box>
            <Box sx={{ margin: '1rem 0 0 0' }}>
              <Box sx={{ fontSize: '20px', marginTop: '20px' }}>Szczegółowe listy klientów</Box>
              <RadioGroup title="" noPadding={true} isLast={true}>
                <Box sx={{ display: 'flex', padding: '0 0 0 .3rem', flexDirection: 'column' }}>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.DETAILED_COUNTERPARTY_LIST)}
                      value={1}
                      name={'detailedCounterpartyList'}
                      key={'b1'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={
                            formik.values[FIELDS.DETAILED_COUNTERPARTY_LIST] ===
                            DetailedCounterpartyList.FileAvailableInServiceAndEmail
                          }
                          disabled={
                            formik.values[FIELDS.REPORT_SHARING_METHOD] === ReportSharingMethod.AvailableInService
                          }
                          id="detailed-counterparty-list-1"
                        />
                      }
                      label={'W pliku dostępnym w serwisie oraz w treści emaila'}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.DETAILED_COUNTERPARTY_LIST)}
                      value={2}
                      name={'detailedCounterpartyList'}
                      key={'b2'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={
                            formik.values[FIELDS.DETAILED_COUNTERPARTY_LIST] ===
                            DetailedCounterpartyList.FileAvailableInService
                          }
                          disabled={
                            formik.values[FIELDS.REPORT_SHARING_METHOD] === ReportSharingMethod.AvailableInService
                          }
                          id="detailed-counterparty-list-2"
                        />
                      }
                      label={'W pliku dostępnym w serwisie'}
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </Box>
          </Box>
          <Box sx={{ padding: '0 1.5rem', width: '50%' }}>
            <RecipientsList
              multiCheckboxHandler={multiCheckboxHandler}
              recipientsValue={formik.values[FIELDS.RECIPIENTS]}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              users={users}
              checkAll={checkAll}
              disabled={formik.values[FIELDS.REPORT_SHARING_METHOD] === ReportSharingMethod.AvailableInService}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            background: '#e7e6e6'
          }}>
          <Box
            sx={{
              padding: '0 1.5rem',
              fontSize: '20px',
              fontWeight: 'bold',
              marginTop: '10px',
              marginBottom: '10px'
            }}>
            Zawartość raportu
          </Box>
        </Box>

        <div className={styles.sectionBox}>
          {showReportContentSectionError && !isAnyReportSectionEnabled && (
            <div className={styles.reportContentError}>Zaznacz przynajmniej jedną opcję inną niż „Wyłączone”.</div>
          )}
          <div className={styles.reportContentBox}>
            <div className={styles.reportContentSection}>
              <h3 className={styles.reportContentSectionHeader}>Kategoria wiarygodności płatniczej</h3>
              <div className={styles.radioButtonBox}>
                <FormControlLabel
                  label={'Wyłączone'}
                  onChange={(event) => stateDefinitionRadioButtonHandler(event, FIELDS.RISK_GROUP_DEFINITION_STATE)}
                  value={1}
                  name={'riskGroupDefinitionStateDisabled'}
                  key={'b1'}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[FIELDS.RISK_GROUP_DEFINITION_STATE]) === 1}
                      id="risk-group-definition-state-1"
                    />
                  }
                />
                <FormControlLabel
                  label={'Informuj o wszystkich zmianach kategorii wiarygodności'}
                  onChange={(event) => stateDefinitionRadioButtonHandler(event, FIELDS.RISK_GROUP_DEFINITION_STATE)}
                  value={2}
                  name={'riskGroupDefinitionStateAllChanges'}
                  key={'b2'}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[FIELDS.RISK_GROUP_DEFINITION_STATE]) === 2}
                      id="risk-group-definition-state-2"
                    />
                  }
                />

                <div>
                  <div className={styles.collapseLabel}>
                    <FormControl error={!!formik.errors[FIELDS.RISK_GROUP_DEFINITION_STATE]}>
                      <FormControlLabel
                        label={'Ustawienia zaawansowane raportu'}
                        onChange={(event) =>
                          stateDefinitionRadioButtonHandler(event, FIELDS.RISK_GROUP_DEFINITION_STATE)
                        }
                        value={3}
                        name={'riskGroupDefinitionStateCustomDefinition'}
                        key={'b3'}
                        control={
                          <StyledRadioButton
                            sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                            checked={Number(formik.values[FIELDS.RISK_GROUP_DEFINITION_STATE]) === 3}
                            id="risk-group-definition-state-3"
                          />
                        }
                      />
                      <FormHelperText>{formik.errors[FIELDS.RISK_GROUP_DEFINITION_STATE]}</FormHelperText>
                    </FormControl>
                  </div>
                  <div
                    className={
                      formik.values[FIELDS.RISK_GROUP_DEFINITION_STATE] === 3
                        ? styles.collapseContent
                        : [styles.collapseContent, styles.hidden].join(' ')
                    }>
                    <div className={styles.collapseContentBox}>
                      <div className={styles.comboBoxGroup}>
                        <FormControlLabel
                          onChange={(event) => checkBoxHandler(event, FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER)}
                          value={true}
                          name={'riskGroupUseImprovementFilter'}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER]}
                            />
                          }
                          label="Polepszenie kategorii klienta"
                        />
                        <ComboBox
                          size="small"
                          values={riskGroupImprovementOptions}
                          selectValue={formik.values[FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD]}
                          handleChange={(value) =>
                            comboBoxHandler(value, FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD)
                          }></ComboBox>
                      </div>
                      <div className={styles.comboBoxGroup}>
                        <FormControlLabel
                          onChange={(event) => checkBoxHandler(event, FIELDS.RISK_GROUP_USE_DECLINE_FILTER)}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.RISK_GROUP_USE_DECLINE_FILTER]}
                            />
                          }
                          label="Pogorszenie kategorii klienta"
                        />
                        <ComboBox
                          size="small"
                          values={riskGroupDeclineOptions}
                          selectValue={formik.values[FIELDS.RISK_GROUP_DECLINE_THRESHOLD]}
                          handleChange={(value) =>
                            comboBoxHandler(value, FIELDS.RISK_GROUP_DECLINE_THRESHOLD)
                          }></ComboBox>
                      </div>
                      <div className={styles.collapseContentBox}>
                        <FormControlLabel
                          onChange={(event) => riskGroupParentCheckBoxHandler(event)}
                          value={true}
                          name={'name'}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.RISK_GROUPS].length > 0}
                              indeterminate={
                                formik.values[FIELDS.RISK_GROUPS].length > 0 &&
                                formik.values[FIELDS.RISK_GROUPS].length !== riskGroupsDict?.riskGroups.length
                              }
                            />
                          }
                          label="Lista aktualnych klientów posiadających kategorię:"
                        />
                        <div className={styles.collapseContent}>
                          <div className={styles.collapseContentBox}>
                            {riskGroupsDict?.riskGroups?.map((riskGroup: RiskGroupItemDto) => (
                              <FormControlLabel
                                onChange={(event) =>
                                  multiCheckboxHandler(event, FIELDS.RISK_GROUPS, formik.values[FIELDS.RISK_GROUPS])
                                }
                                value={riskGroup.id}
                                name={'riskGroupCode'}
                                key={riskGroup.id}
                                control={
                                  <StyledCheckbox
                                    sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem', pointerEvents: 'none' }}
                                    checked={formik.values[FIELDS.RISK_GROUPS].includes(riskGroup.id)}
                                    id={`new-report-select-${riskGroup?.code}-risk-checkbox`}
                                  />
                                }
                                label={
                                  riskGroup.code === notCalculatedRiskGroup
                                    ? 'Kategoria niewyznaczona'
                                    : `${riskGroup.code} - ${riskGroup.value}`
                                }
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <StyledButton
                      id="reset-riskgroup-advanced-settings"
                      sx={{ marginLeft: '-2rem', marginTop: '0.75rem' }}
                      {...resetRiskGroupAdvancedSettingsButtonProps}>
                      Ustaw domyślne
                    </StyledButton>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.reportContentSection}>
              <h3 className={styles.reportContentSectionHeader}>Limit kupiecki</h3>
              <div className={styles.radioButtonBox}>
                <FormControlLabel
                  label={'Wyłączone'}
                  onChange={(event) =>
                    stateDefinitionRadioButtonHandler(event, FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE)
                  }
                  value={1}
                  key={'b1'}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]) === 1}
                      id="trade-credit-limit-definition-state-1"
                    />
                  }
                />
                <FormControlLabel
                  label={'Informuj o wszystkich zmianach limitu kupieckiego'}
                  onChange={(event) =>
                    stateDefinitionRadioButtonHandler(event, FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE)
                  }
                  value={2}
                  key={'b2'}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]) === 2}
                      id="trade-credit-limit-definition-state-2"
                    />
                  }
                />

                <div>
                  <div className={styles.collapseLabel}>
                    <FormControl error={!!formik.errors[FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]}>
                      <FormControlLabel
                        label={'Ustawienia zaawansowane raportu'}
                        onChange={(event) =>
                          stateDefinitionRadioButtonHandler(event, FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE)
                        }
                        value={3}
                        name={'tradeCreditLimitDefinitionState'}
                        key={'b3'}
                        control={
                          <StyledRadioButton
                            sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                            checked={Number(formik.values[FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]) === 3}
                            id="trade-credit-limit-definition-state-3"
                          />
                        }
                      />
                      <FormHelperText>{formik.errors[FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]}</FormHelperText>
                    </FormControl>
                  </div>
                  <div
                    className={
                      formik.values[FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE] === 3
                        ? styles.collapseContent
                        : [styles.collapseContent, styles.hidden].join(' ')
                    }>
                    <div className={styles.collapseContentBox}>
                      <div className={styles.comboBoxGroup}>
                        <FormControlLabel
                          onChange={(event) =>
                            checkBoxHandler(event, FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD)
                          }
                          value={true}
                          name={'tradeCreditLimitUseImprovementThreshold'}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD]}
                            />
                          }
                          label="Zwiększenie limitu kupieckiego"
                        />
                        <ComboBox
                          size="small"
                          values={tradeCreditLimitImprovementOptions}
                          selectValue={formik.values[FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD]}
                          handleChange={(value) =>
                            comboBoxHandler(value, FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD)
                          }></ComboBox>
                      </div>
                      <div className={styles.comboBoxGroup}>
                        <FormControlLabel
                          onChange={(event) => checkBoxHandler(event, FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD)}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD]}
                            />
                          }
                          label="Zmniejszenie limitu kupieckiego"
                        />
                        <ComboBox
                          size="small"
                          values={tradeCreditLimitDeclineOptions}
                          selectValue={formik.values[FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD]}
                          handleChange={(value) =>
                            comboBoxHandler(value, FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD)
                          }></ComboBox>
                      </div>
                      <div className={styles.collapseContentBox}>
                        <FormControlLabel
                          onChange={(event) => tradeCreditLimitParentCheckBoxHandler(event)}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER].length > 0}
                              indeterminate={
                                formik.values[FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER].length > 0 &&
                                formik.values[FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER].length !==
                                  tradeCreditLimitRanges?.length
                              }
                            />
                          }
                          label="Lista aktualnych klientów posiadających limit kupiecki:"
                        />

                        <div className={styles.collapseContent}>
                          <div className={styles.collapseContentBox}>
                            {tradeCreditLimitRanges.map((range, index) => (
                              <FormControlLabel
                                onChange={(event) =>
                                  tradeCreditLimitMultiCheckboxHandler(
                                    event,
                                    FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER,
                                    formik.values[FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER]
                                  )
                                }
                                value={index}
                                name={'tradeCreditLimitRange'}
                                key={index}
                                control={
                                  <StyledCheckbox
                                    sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem', pointerEvents: 'none' }}
                                    checked={formik.values[FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER].includes(range)}
                                    id={`new-report-select-${index}-tcl-range-checkbox`}
                                  />
                                }
                                label={getTradeCreditRangeLabel(range)}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <StyledButton
                      id="reset-tradecreditlimit-advanced-settings"
                      sx={{ marginLeft: '-2rem', marginTop: '0.75rem' }}
                      {...resetTradeCreditLimitAdvancedSettingsButtonProps}>
                      Ustaw domyślne
                    </StyledButton>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.reportContentSection}>
              <h3 className={styles.reportContentSectionHeader}>Zadłużenie</h3>
              <div className={styles.radioButtonBox}>
                <FormControlLabel
                  label={'Wyłączone'}
                  onChange={(event) => stateDefinitionRadioButtonHandler(event, FIELDS.DEBT_DEFINITION_STATE)}
                  value={1}
                  key={'b1'}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[FIELDS.DEBT_DEFINITION_STATE]) === 1}
                      id="debt-definition-state-1"
                    />
                  }
                />
                <FormControlLabel
                  label={'Informuj o wszystkich zmianach zadłużenia'}
                  onChange={(event) => stateDefinitionRadioButtonHandler(event, FIELDS.DEBT_DEFINITION_STATE)}
                  value={2}
                  key={'b2'}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[FIELDS.DEBT_DEFINITION_STATE]) === 2}
                      id="debt-definition-state-2"
                    />
                  }
                />
                <div>
                  <div className={styles.collapseLabel}>
                    <FormControl error={!!formik.errors[FIELDS.DEBT_DEFINITION_STATE]}>
                      <FormControlLabel
                        label={'Ustawienia zaawansowane raportu'}
                        onChange={(event) => stateDefinitionRadioButtonHandler(event, FIELDS.DEBT_DEFINITION_STATE)}
                        value={3}
                        name={'debtDefinitionState'}
                        key={'b3'}
                        control={
                          <StyledRadioButton
                            sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                            checked={Number(formik.values[FIELDS.DEBT_DEFINITION_STATE]) === 3}
                            id="debt-definition-state-3"
                          />
                        }
                      />
                      <FormHelperText>{formik.errors[FIELDS.DEBT_DEFINITION_STATE]}</FormHelperText>
                    </FormControl>
                  </div>
                  <div
                    className={
                      formik.values[FIELDS.DEBT_DEFINITION_STATE] === 3
                        ? styles.collapseContent
                        : [styles.collapseContent, styles.hidden].join(' ')
                    }>
                    <div className={styles.collapseContentBox}>
                      <div className={styles.comboBoxGroup}>
                        <FormControlLabel
                          onChange={(event) => checkBoxHandler(event, FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD)}
                          value={true}
                          name={'debtUseImprovementThreshold'}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD]}
                            />
                          }
                          label="Zwiększenie zadłużenia całkowitego"
                        />
                        <ComboBox
                          size="small"
                          values={debtImprovementOptions}
                          selectValue={formik.values[FIELDS.DEBT_IMPROVEMENT_THRESHOLD]}
                          handleChange={(value) =>
                            comboBoxHandler(value, FIELDS.DEBT_IMPROVEMENT_THRESHOLD)
                          }></ComboBox>
                      </div>
                      <div className={styles.comboBoxGroup}>
                        <FormControlLabel
                          onChange={(event) => checkBoxHandler(event, FIELDS.DEBT_USE_DECLINE_THRESHOLD)}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.DEBT_USE_DECLINE_THRESHOLD]}
                            />
                          }
                          label="Zmniejszenie zadłużenia całkowitego"
                        />
                        <ComboBox
                          size="small"
                          values={debtDeclineOptions}
                          selectValue={formik.values[FIELDS.DEBT_DECLINE_THRESHOLD]}
                          handleChange={(value) => comboBoxHandler(value, FIELDS.DEBT_DECLINE_THRESHOLD)}></ComboBox>
                      </div>
                      <div className={styles.comboBoxGroup}>
                        <FormControlLabel
                          onChange={(event) => checkBoxHandler(event, FIELDS.DEBT_ADDING_DEBTOR)}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.DEBT_ADDING_DEBTOR]}
                            />
                          }
                          label="Dodanie dłużnika do bazy KRD BIG S.A."
                        />
                      </div>
                      <div className={styles.comboBoxGroup}>
                        <FormControlLabel
                          onChange={(event) => checkBoxHandler(event, FIELDS.DEBT_REMOVING_DEBTOR)}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.DEBT_REMOVING_DEBTOR]}
                            />
                          }
                          label="Usunięcie dłużnika z bazy KRD BIG S.A."
                        />
                      </div>

                      <div className={styles.collapseContentBox}>
                        <FormControlLabel
                          onChange={(event) => debtParentCheckBoxHandler(event)}
                          control={
                            <StyledCheckbox
                              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                              checked={formik.values[FIELDS.DEBT_RANGE_FILTER].length > 0}
                              indeterminate={
                                formik.values[FIELDS.DEBT_RANGE_FILTER].length > 0 &&
                                formik.values[FIELDS.DEBT_RANGE_FILTER].length !== debtRanges?.length
                              }
                            />
                          }
                          label="Lista aktualnych klientów posiadających zadłużenie całkowite:"
                        />

                        <div className={styles.collapseContent}>
                          <div className={styles.collapseContentBox}>
                            {debtRanges.map((range, index) => (
                              <FormControlLabel
                                onChange={(event) =>
                                  debtMultiCheckboxHandler(
                                    event,
                                    FIELDS.DEBT_RANGE_FILTER,
                                    formik.values[FIELDS.DEBT_RANGE_FILTER]
                                  )
                                }
                                value={index}
                                name={'debtRange'}
                                key={index}
                                control={
                                  <StyledCheckbox
                                    sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem', pointerEvents: 'none' }}
                                    checked={formik.values[FIELDS.DEBT_RANGE_FILTER].includes(range)}
                                    id={`new-report-select-${index}-debt-range-checkbox`}
                                  />
                                }
                                label={getDebtRangeLabel(range)}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <StyledButton
                      id="reset-debt-advanced-settings"
                      sx={{ marginLeft: '-2rem', marginTop: '0.75rem' }}
                      {...resetDebtAdvancedSettingsButtonProps}>
                      Ustaw domyślne
                    </StyledButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Box
          sx={{
            display: 'flex',
            borderBottom: '1px solid #E0E0E0',
            paddingBottom: '2.25rem',
            justifyContent: 'center'
          }}>
          <Stack
            spacing={3}
            direction="row"
            sx={{ padding: '1.5rem 1.5rem 0.5rem 1.5rem', textTransform: 'uppercase' }}>
            <StyledButton id="new-report-save-button" {...acceptButtonProps}>
              Zapisz raport
            </StyledButton>
            <StyledButton id="new-report-clean-button" {...cleanButtonProps}>
              Wyczyść
            </StyledButton>
            <StyledButton id="new-report-cancel-button" variant="text" onMouseDown={toggleCancelModal}>
              Anuluj
            </StyledButton>
          </Stack>
        </Box>
      </form>

      {inEditMode && (
        <Dialog
          isOpen={[...validationErrors].length === 0 && isAcceptModalOpen}
          isPending={isDataSending}
          closeModal={() => setAcceptModalOpen(false)}
          accept={formik.submitForm}
          acceptLabel="Tak"
          closeLabel="Nie">
          Czy chcesz zapisać wprowadzone zmiany?
        </Dialog>
      )}

      <Dialog {...cleanDialogProps}>{cleanButtonLabel}</Dialog>

      <Dialog {...cancelDialogProps}>{cancelButtonLabel}</Dialog>
    </Box>
  );
};

export {
  allChangesOptionValue,
  noDebtOptionValue,
  tradeCreditLimitRanges,
  debtRanges,
  tradeCreditLimitDeclineThresholdsDefaultOptionValue,
  debtImprovementTresholdDefaultOptionValue
};

export default ReportForm;
