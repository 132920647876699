import {
  ApiResponse,
  ReportRecipientItemDto,
  ReportUsersDto,
  ReportUserDto,
  RiskGroupReportDefiniitionDto,
  TradeCreditLimitDefinitionState,
  TradeCreditLimitFilterDto,
  TradeCreditLimitRangeDto,
  DebtReportDefinitionDto,
  DebtDefinitionState,
  DebtRangeDto
} from 'api/models';
import { AxiosPromise } from 'axios';
import { ReactElement } from 'react';

export enum FIELDS {
  COMPANY_ID = 'companyId',
  REPORT_ID = 'reportId',
  NAME = 'name',
  FREQUENCY = 'frequency',
  RECIPIENTS = 'recipients',
  REPORT_SHARING_METHOD = 'reportSharingMethod',
  DETAILED_COUNTERPARTY_LIST = 'detailedCounterpartyList',

  RISK_GROUP_DEFINITION_STATE = 'riskGroupDefinitionState',
  RISK_GROUP_USE_IMPROVEMENT_FILTER = 'riskGroupUseImprovementFilter',
  RISK_GROUP_IMPROVEMENT_THRESHOLD = 'riskGroupImprovementThreshold',
  RISK_GROUP_USE_DECLINE_FILTER = 'riskGroupUseDeclineFilter',
  RISK_GROUP_DECLINE_THRESHOLD = 'riskGroupDeclineThreshold',
  RISK_GROUPS = 'riskGroups',

  TRADE_CREDIT_LIMIT_DEFINITION_STATE = 'tradeCreditLimitDefinitionState',
  TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD = 'tradeCreditLimitUseImprovementThreshold',
  TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD = 'tradeCreditLimitImprovementThreshold',
  TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD = 'tradeCreditLimitUseDeclineThreshold',
  TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD = 'tradeCreditLimitDeclineThreshold',
  TRADE_CREDIT_LIMIT_RANGE_FILTER = 'tradeCreditLimitRangeFilter',

  DEBT_DEFINITION_STATE = 'debtDefinitionState',
  DEBT_USE_IMPROVEMENT_THRESHOLD = 'debtUseImprovementThreshold',
  DEBT_IMPROVEMENT_THRESHOLD = 'debtImprovementThreshold',
  DEBT_USE_DECLINE_THRESHOLD = 'debtUseDeclineThreshold',
  DEBT_DECLINE_THRESHOLD = 'debtDeclineThreshold',
  DEBT_ADDING_DEBTOR = 'debtAddingDebtor',
  DEBT_REMOVING_DEBTOR = 'debtRemovingDebtor',
  DEBT_RANGE_FILTER = 'debtRangeFilter'
}

export type FormikValues = {
  [FIELDS.COMPANY_ID]: number;
  [FIELDS.REPORT_ID]: number;
  [FIELDS.NAME]: string;
  [FIELDS.FREQUENCY]: number;
  [FIELDS.RECIPIENTS]: number[];
  [FIELDS.DETAILED_COUNTERPARTY_LIST]: number;
  [FIELDS.REPORT_SHARING_METHOD]: number;

  [FIELDS.RISK_GROUP_DEFINITION_STATE]: number;
  [FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER]: boolean;
  [FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD]: string;
  [FIELDS.RISK_GROUP_USE_DECLINE_FILTER]: boolean;
  [FIELDS.RISK_GROUP_DECLINE_THRESHOLD]: string;
  [FIELDS.RISK_GROUPS]: number[];

  [FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]: TradeCreditLimitDefinitionState;
  [FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD]: boolean;
  [FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD]: string;
  [FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD]: boolean;
  [FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD]: string;
  [FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER]: TradeCreditLimitRangeDto[];

  [FIELDS.DEBT_DEFINITION_STATE]: DebtDefinitionState;
  [FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD]: boolean;
  [FIELDS.DEBT_IMPROVEMENT_THRESHOLD]: string;
  [FIELDS.DEBT_USE_DECLINE_THRESHOLD]: boolean;
  [FIELDS.DEBT_DECLINE_THRESHOLD]: string;
  [FIELDS.DEBT_ADDING_DEBTOR]: boolean;
  [FIELDS.DEBT_REMOVING_DEBTOR]: boolean;
  [FIELDS.DEBT_RANGE_FILTER]: DebtRangeDto[];
};

export type FormikSubmitValues = {
  [FIELDS.COMPANY_ID]: number;
  [FIELDS.NAME]: string;
  [FIELDS.FREQUENCY]: number;
  [FIELDS.RECIPIENTS]: ReportRecipientItemDto[];
  [FIELDS.REPORT_SHARING_METHOD]: number;
  [FIELDS.DETAILED_COUNTERPARTY_LIST]: number;
  riskGroupDefinition: RiskGroupReportDefiniitionDto;
  tradeCreditLimitFilter: TradeCreditLimitFilterDto | null;
  debtReportDefinition: DebtReportDefinitionDto | null;
};

export type FieldItem = {
  name: FIELDS;
  label: string;
  value: string | number;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  styles?: object;
  tooltipText?: string | ReactElement;
};

export type FieldType = FieldItem[];

export type Props = {
  inEditMode?: boolean;
  initialValues: FormikValues;
  submitHandler: (values: FormikSubmitValues) => AxiosPromise<ApiResponse<string>>;
};

export type RecipientsListProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  checkAll: (event: any, field: FIELDS, availableValues: number[]) => void;
  users: ReportUsersDto | null;
  recipientsValue: number[];
  multiCheckboxHandler: (event: any, field: FIELDS, currentValues: number[]) => void;
  disabled: boolean;
};

export type FormControlProps = {
  reportUser: ReportUserDto;
  multiCheckboxHandler: (event: any, field: FIELDS, currentValues: number[]) => void;
  recipientsValue: number[];
  disabled: boolean;
};

export type RecipientsProps = {
  multiCheckboxHandler: (event: any, field: FIELDS, currentValues: number[]) => void;
  recipientsValue: number[];
  users: ReportUsersDto | null;
  searchValue: string;
  disabled: boolean;
};
